@import url("https://fonts.googleapis.com/css?family=Lato|Open+Sans|Roboto &display=swap");
.centerMenu {
  justify-content: center; }

.ui.menu.main-menu {
  border-bottom: 1px solid #1192e7;
  background: rgba(11, 36, 65, 0.8);
  box-shadow: 1px 1px 8px #232020 !important; }

.ui.labeled.icon.button,
.ui.labeled.icon.buttons .button {
  position: relative;
  padding-left: 1.8rem !important;
  padding-right: 1.5em !important;
  font-size: 1.2rem; }

.ui.menu {
  background: rgba(11, 36, 65, 0.8);
  font-size: 1.2rem;
  font-family: "Exo", sans-serif;
  margin: 0;
  border: none;
  box-shadow: none;
  border-radius: 0; }
  .ui.menu .main-menu,
  .ui.menu .footer-menu {
    width: 100%;
    background: rgba(11, 36, 65, 0.8);
    flex: 1;
    color: #fff;
    font-size: 1.4rem; }
  .ui.menu .item {
    color: #fff; }
  .ui.menu a.item:hover {
    color: #41b6e6; }
  .ui.menu .item.disabled,
  .ui.menu .item.disabled:hover {
    cursor: default !important;
    background-color: transparent !important;
    color: rgba(103, 94, 94, 0.8) !important; }
  .ui.menu .active.item {
    color: #41b6e6; }
  .ui.menu .active.item:hover,
  .ui.menu .ui.vertical.menu .active.item:hover {
    color: #41b6e6; }
  .ui.menu svg {
    margin-right: 0.5rem; }

.ui.menu .item.disabled,
.ui.menu .item.disabled:hover {
  color: #e8edee !important; }

.ui.link.menu .item:hover,
.ui.menu .dropdown.item:hover,
.ui.menu .link.item:hover,
.ui.menu a.item:hover {
  color: #41b6e6; }

.ui.menu .dropdown.item .menu {
  min-width: max-content !important;
  width: calc(100% + 10px) !important; }

.ui.menu.sub-menu {
  margin-top: 0;
  border-bottom: 1px solid rgba(199, 199, 199, 0.63); }

.settings-menu .primary {
  color: #fbbd08; }

.ui.menu.subMenu {
  border-bottom: 1px solid #1192e7; }
  .ui.menu.subMenu .primary {
    color: #b4b4b4; }

.ui.footer-menu {
  border-top: 1px solid rgba(199, 199, 199, 0.342);
  box-shadow: 1px 1px 8px #232020 !important; }

.ui.attached.menu:not(.tabular) {
  border-radius: 0;
  border: 1px solid #1192e7; }

.ui.secondary.menu .active.item {
  color: #fff; }

.ui.secondary.menu .item {
  color: #b4b4b4; }

.ui.secondary.menu .dropdown.item:hover,
.ui.secondary.menu .link.item:hover,
.ui.secondary.menu a.item:hover {
  color: #b4b4b4; }

.ui.secondary.menu .active.item:hover {
  color: #fff; }

.ui.tabular.menu .item {
  color: #b4b4b4; }

.ui.tabular.menu .active.item {
  background: rgba(11, 36, 65, 0.8);
  color: #fff; }

.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="file"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="text"],
.ui.form input[type="time"],
.ui.form input[type="url"],
.ui.form textarea,
.ui.input > input,
.ui.selection.dropdown {
  background: rgba(11, 36, 65, 0.8);
  color: #fff; }

.ui.selection.dropdown.required {
  border-color: rgba(255, 0, 0, 0.384); }

.ui .input {
  color: #fff; }
  .ui .input > input {
    border-radius: 0; }

.inputStyle {
  border-bottom: 1px solid #1192e7; }

.menuInput input {
  background: #0b1720 !important; }

.menuInput > input:focus {
  color: #fff !important; }

textarea {
  background: rgba(11, 36, 65, 0.8);
  color: #fff;
  width: 100%;
  border-radius: 0.28rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 1em;
  line-height: 1.2857;
  resize: vertical; }

.ui.form input:not([type]):focus,
.ui.form input[type="date"]:focus,
.ui.form input[type="datetime-local"]:focus,
.ui.form input[type="email"]:focus,
.ui.form input[type="file"]:focus,
.ui.form input[type="number"]:focus,
.ui.form input[type="password"]:focus,
.ui.form input[type="search"]:focus,
.ui.form input[type="tel"]:focus,
.ui.form input[type="text"]:focus,
.ui.form input[type="time"]:focus,
.ui.form input[type="url"]:focus,
.ui.form textarea:focus {
  background: rgba(11, 36, 65, 0.8);
  color: #fff; }

.ui.form .field > label {
  color: #b4b4b4 !important; }

.ui.form .field.error input:not([type]) {
  background: #610e0e !important; }

.ui.secondary.pointing.menu .active.item {
  color: #e8edee !important;
  border-color: #1192e7; }

.ui.secondary.pointing.menu .item:hover {
  color: #41b6e6 !important;
  border-color: #0e74b7 !important; }

.settingsInput {
  font-size: 1.4rem !important; }

.locForm {
  background: #0b1720 !important;
  border-radius: 1rem !important; }

.ui.toggle.checkbox .box,
.ui.toggle.checkbox label,
.ui.checkbox input:focus ~ label,
.ui.checkbox label,
.ui.checkbox + label,
.ui.checkbox label:hover,
.ui.checkbox + label:hover {
  color: #b4b4b4; }

.ui.toggle.checkbox input:focus:checked ~ .box,
.ui.toggle.checkbox input:checked ~ label,
.ui.toggle.checkbox input:focus:checked ~ label {
  color: green !important; }

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #21ba45 !important; }

.inner-container {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .inner-container .sub-header {
    text-align: center;
    font-size: 18px;
    margin-bottom: 7px; }
  .inner-container .draggable-container-hovered {
    border: 2px dashed white !important; }
  .inner-container .draggable-container {
    min-width: 90%;
    max-width: 95%;
    min-height: 7rem;
    max-height: 8rem;
    background-color: rgba(147, 148, 148, 0.23);
    padding: 6px;
    border-radius: 4px;
    border: 2px dashed rgba(95, 92, 92, 0.2);
    font-size: 14px;
    display: flex;
    margin-bottom: 7px;
    position: relative;
    overflow: hidden; }
    .inner-container .draggable-container .hovered {
      border: 2px dashed white !important; }
    .inner-container .draggable-container #file-browser-input {
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      position: absolute;
      color: transparent;
      opacity: 0;
      z-index: 10; }
    .inner-container .draggable-container .files-preview-container {
      display: flex;
      width: 100%;
      height: fit-content;
      flex-direction: row;
      flex-wrap: nowrap;
      z-index: 99;
      overflow-x: auto;
      overflow-y: hidden;
      flex-shrink: 0;
      padding: 5px; }
      .inner-container .draggable-container .files-preview-container .file {
        width: 5em;
        height: 58px;
        background-color: rgba(101, 97, 97, 0.34);
        position: relative;
        margin-left: 8px;
        border: 1px solid #fff;
        border-radius: 4px;
        box-shadow: 0px 0px 14px 3px rgba(15, 15, 15, 0.2);
        flex-shrink: 0; }
        .inner-container .draggable-container .files-preview-container .file img {
          width: 100%;
          height: 100%; }
        .inner-container .draggable-container .files-preview-container .file .container {
          display: flex;
          position: absolute;
          flex-direction: column;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 0;
          transition: background, 160ms ease-in-out; }
          .inner-container .draggable-container .files-preview-container .file .container:hover {
            background-color: rgba(154, 151, 151, 0.29); }
            .inner-container .draggable-container .files-preview-container .file .container:hover .remove-btn {
              visibility: visible; }
          .inner-container .draggable-container .files-preview-container .file .container .remove-btn {
            color: #ca4240;
            width: fit-content;
            position: absolute;
            right: -7px;
            top: -7px;
            z-index: 99;
            cursor: pointer;
            visibility: hidden; }
          .inner-container .draggable-container .files-preview-container .file .container .progress-bar {
            position: absolute;
            width: 53px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-self: center; }
    .inner-container .draggable-container .helper-text {
      position: absolute;
      align-self: center;
      color: #797676;
      text-align: center;
      padding: 0.5rem;
      width: 100%; }
    .inner-container .draggable-container .file-browser-container {
      position: absolute;
      display: flex;
      justify-self: center;
      align-self: center;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%); }

.ui.input.down input,
.ui.input > input:active {
  background: #b4b4b4; }

.ui.form .formActions {
  justify-content: space-between;
  display: flex;
  border-top: 1px solid #1192e7;
  padding: 1rem;
  margin-top: 0.4rem; }

.ui.selection.dropdown {
  min-width: 7rem; }

.ui.grid.formGrid .field {
  padding: 0.6rem; }

/**
* The geosuggest module
* NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
*/
.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  width: 100%;
  /* margin: 1em auto; */
  text-align: left; }

.geosuggest__input {
  width: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 1px #3d464d;
  padding: 0.5em 1em;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;
  color: #222; }

.geosuggest__input:focus {
  border-color: #e58f38;
  box-shadow: 0 0 0 transparent; }

.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #333;
  border: 2px solid #e58f38;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s; }

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0; }

/**
  * A geosuggest item
  */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer; }

.geosuggest__item:hover,
.geosuggest__item:focus {
  background: rgba(11, 36, 65, 0.8); }

.geosuggest__item--active {
  background: #a25c15;
  color: #fff; }

.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc; }

.geosuggest__item__matched-text {
  font-weight: bold; }

.ui.table {
  background: #0b1720;
  color: #fff;
  font-size: 1em; }
  .ui.table .hidden {
    color: grey !important; }
  .ui.table .highLight {
    color: #b4b4b4;
    font-size: 1.1rem; }
  .ui.table .headerCell {
    background: rgba(11, 36, 65, 0.8); }
  .ui.table .ui.label {
    margin-bottom: 0.2rem; }

.ui.attached.table {
  border-color: #b4b4b4; }

.ui.table thead th {
  background: rgba(11, 36, 65, 0.8);
  color: #b4b4b4; }

.ui.selectable.table tbody tr:hover,
.ui.table tbody tr td.selectable:hover {
  color: #b4b4b4 !important;
  cursor: pointer; }

.ui.selectable.table tbody tr.subtleRow {
  background: #aef30d0f; }
  .ui.selectable.table tbody tr.subtleRow:hover {
    background: #aef30d0f !important; }

.ui.table td.warning,
.ui.table tr.warning {
  background: rgba(9, 154, 251, 0.102) !important;
  background: rgba(11, 36, 65, 0.8);
  color: #41b6e6 !important; }

.ui.table td.positive,
.ui.table tr.positive {
  background: #aef30d0f !important;
  color: #fff !important; }

.ui.table td.negative,
.ui.table tr.negative {
  background: #e94c4c41 !important;
  color: #fdf5f5 !important; }

.ui.table.jobList tr.negative {
  background: #fc0e0e91 !important;
  border: 1px solid red; }

.headerRow {
  background: #0b1720 !important; }
  .headerRow th {
    background: #0b1720 !important; }

.detailRow {
  background: #183246; }

.headerRow.title {
  font-size: 1.4rem; }

.fieldTitle {
  padding-left: 3rem !important; }

.ui.table tr td {
  border-top: 1px solid #073858 !important; }

.ui.table.filter td:hover {
  cursor: pointer; }

.ui.table.filter {
  background: rgba(11, 36, 65, 0.8); }

.ui.table tr:hover td.hidden {
  pointer-events: none;
  color: #847979; }

.ui.celled.table tr td,
.ui.celled.table tr th {
  border-left: 1px solid rgba(123, 123, 123, 0.4); }

.ui.attached.table {
  border: 1px solid #1192e7; }

.locTable .title {
  letter-spacing: 0.3rem;
  font-family: Exo, Roboto, "Arial Narrow Bold", sans-serif; }

.locTable .borderTop {
  border-top: 2px solid #41b6e6; }

.locTable .borderBottom {
  border-bottom: 2px solid #fff; }

.theMap {
  width: 100%;
  height: 56vh; }

.mapHolder {
  height: 100%;
  border: 1px solid #1192e7 !important; }

.mapHolder1 {
  height: 150mm; }

.mapOuterHolder {
  height: 70vh; }

.basicMap {
  width: 100%;
  min-height: 30vh;
  color: #000;
  flex: 1; }
  .basicMap .mapboxgl-ctrl-top-left {
    top: 60px;
    left: 0; }
  .basicMap .messageBox {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 10px !important;
    background: rgba(250, 250, 250, 0.2);
    margin: 0 auto !important;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px; }
    .basicMap .messageBox .button {
      margin-left: 20px; }
    .basicMap .messageBox .header {
      margin: 0;
      color: #f3aa0b; }

.taskMap .messageBox {
  position: absolute;
  top: 10px;
  left: 180px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px; }
  .taskMap .messageBox .button {
    margin-left: 20px; }
  .taskMap .messageBox .header {
    margin: 0;
    color: #f3aa0b; }

.areaMap {
  min-height: 60vh; }

.locMap {
  min-height: 60vh; }

.taskMap {
  width: 100%;
  min-height: 56vh; }

.overviewMap {
  width: 100%;
  min-height: 80vh;
  color: black; }

.locateMap {
  width: 100%;
  min-height: 70vh;
  color: black; }

.mainMapHolder {
  height: 85vh;
  padding: 0 !important;
  margin: 0 !important; }

.gridBox {
  font-size: 1.4rem;
  position: absolute;
  z-index: 999;
  background: black;
  padding: 1rem;
  color: #fff;
  min-width: 150px;
  border-bottom: 1px solid white;
  border-right: 0 solid white;
  height: 50px; }

.gridBox2 {
  font-size: 1.4rem;
  position: absolute;
  left: 150px;
  height: 50px;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  padding: 1rem;
  color: white;
  min-width: 200px;
  border-bottom: 1px solid white; }

.mapBar {
  position: absolute;
  top: 100px;
  /* left: 20px; */
  right: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  flex-direction: column;
  padding: 0.8rem;
  border-radius: 0; }
  .mapBar .button {
    margin: 0.5rem 0; }

.taskMap .mapBar {
  top: 80px; }

.taskMap .layerBar {
  min-width: 100px; }

.taskGridBox {
  position: absolute;
  z-index: 999;
  background: rgba(0, 0, 0, 0.4);
  font-size: 1rem;
  border-right: 1px solid white;
  padding: 0.5rem;
  min-width: 98px;
  height: 40px; }

.layerBar {
  position: absolute;
  flex-direction: column;
  min-width: 150px;
  height: 100%;
  padding-top: 50px;
  border-radius: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  left: 0; }
  .layerBar .ui.vertical.menu {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    border-bottom: 1px solid white;
    border-right: 3px solid white;
    padding-top: 50px; }
    .layerBar .ui.vertical.menu .item {
      padding: 10px;
      margin: 5px;
      border: 1px solid rgba(255, 255, 255, 0.432); }
    .layerBar .ui.vertical.menu .active.item {
      background: rgba(0, 128, 0, 0.4);
      border: 1px solid #02ff02;
      color: #02ff02; }
    .layerBar .ui.vertical.menu .active.item:hover {
      color: white; }

.layerHolder {
  position: absolute;
  top: 80px;
  left: 20px;
  min-width: 80px;
  padding-bottom: 0.8rem;
  background: rgba(0, 0, 0, 0); }
  .layerHolder .ui.vertical.menu {
    min-width: 2rem;
    width: auto;
    background: rgba(0, 0, 0, 0); }
    .layerHolder .ui.vertical.menu .item.active {
      background: green;
      color: white; }
    .layerHolder .ui.vertical.menu .item.active:hover {
      background: green;
      color: #ccc; }
    .layerHolder .ui.vertical.menu .item {
      margin: 0.8rem;
      background: rgba(14, 98, 189, 0.6); }

.topBarOverview {
  position: absolute;
  width: 100%;
  height: 50px;
  border-radius: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  left: 0; }
  .topBarOverview .ui.menu {
    padding-left: 150px;
    background: rgba(0, 0, 0, 0.6);
    justify-content: center;
    border-bottom: 1px solid white; }
    .topBarOverview .ui.menu .item {
      padding: 10px;
      margin: 5px;
      border: 1px solid rgba(255, 255, 255, 0.432); }
    .topBarOverview .ui.menu .item:hover {
      color: white;
      background: rgba(0, 128, 0, 0.4);
      border: 1px solid #02ff02; }
    .topBarOverview .ui.menu .active.item {
      background: rgba(0, 128, 0, 0.4);
      border: 1px solid #02ff02;
      color: #02ff02; }
    .topBarOverview .ui.menu .active.item:hover {
      color: white; }

.ui.topBar.menu {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  border-radius: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  left: 0; }
  .ui.topBar.menu .item {
    background: rgba(0, 0, 0, 0.6);
    padding: 10px;
    margin: 5px;
    border: 1px solid rgba(255, 255, 255, 0.432); }
  .ui.topBar.menu .item:hover {
    color: white;
    background: rgba(0, 128, 0, 0.4);
    border: 1px solid #02ff02; }
  .ui.topBar.menu .active.item {
    background: rgba(0, 128, 0, 0.4);
    border: 1px solid #02ff02;
    color: #02ff02; }
  .ui.topBar.menu .active.item:hover {
    color: white; }

.markerPopup .mapboxgl-popup-content {
  color: #b4b4b4; }

.fullscreen .basicMap,
.fullscreen .overviewMap,
.fullscreen .taskMap {
  height: 100%; }

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #0b1720; }

.locationPopup {
  text-align: left; }
  .locationPopup .ui.card {
    box-shadow: none; }
    .locationPopup .ui.card .header {
      color: #b4b4b4;
      text-align: center; }

.mapForm {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.6) !important;
  border-radius: 0 !important;
  margin: 1rem !important;
  width: 200px !important; }
  .mapForm .content {
    padding-top: 0 !important; }
  .mapForm h2.header {
    color: #fff;
    text-align: center;
    margin-bottom: 0 !important; }

.taskButtonsHolder {
  position: absolute;
  top: 50px;
  /* left: 20px; */
  left: 10px;
  display: flex;
  -ms-flex-pack: justify;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  flex-direction: column;
  padding: 0.4rem;
  border-radius: 1rem; }
  .taskButtonsHolder .button {
    margin: 0.4rem; }

.ui.circular.button > .icon {
  width: 100%; }

.basicMap .mapbox-gl-draw_ctrl-draw-btn {
  width: 60px !important;
  height: 60px !important;
  background-size: contain; }

.basicMap .mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon.active {
  background-color: rgba(13, 164, 224, 0.603); }

.gm-style .gm-style-iw-c {
  color: black; }

.googleMap .messageBox {
  position: absolute;
  top: 50px;
  left: 10%;
  padding: 10px !important;
  background: rgba(0, 0, 0, 0.6);
  margin: 0 auto !important;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px; }
  .googleMap .messageBox .button {
    margin-left: 20px; }
  .googleMap .messageBox .header {
    margin: 0;
    color: #f3aa0b; }

.googleMap .layerBar1 {
  z-index: 999;
  position: absolute;
  flex-direction: column;
  min-width: 100px;
  height: 100%;
  padding-top: 50px;
  margin-top: 100px;
  border-radius: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  left: 0; }
  .googleMap .layerBar1 .ui.vertical.menu {
    background: rgba(117, 78, 78, 0);
    width: 100%;
    border: none;
    border-bottom: 1px solid white;
    padding-top: 50px; }
    .googleMap .layerBar1 .ui.vertical.menu .item {
      text-align: center;
      padding: 10px;
      margin: 5px;
      background: rgba(126, 127, 126, 0.8);
      border: 1px solid #353331; }
    .googleMap .layerBar1 .ui.vertical.menu .active.item {
      background: rgba(0, 128, 0, 0.8);
      border: 1px solid #02ff02;
      color: #02ff02;
      font-weight: bold !important; }
    .googleMap .layerBar1 .ui.vertical.menu .active.item:hover {
      color: #fff;
      font-weight: bolder !important; }

.mapBar1 {
  z-index: 999;
  position: absolute;
  top: 20px;
  /* left: 20px; */
  right: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  flex-direction: column;
  padding: 1rem;
  border-radius: 1rem; }
  .mapBar1 .ui.vertical.menu {
    background: rgba(0, 0, 0, 0.6);
    width: 100%; }
    .mapBar1 .ui.vertical.menu .item {
      padding: 10px;
      margin: 5px;
      border: 1px solid rgba(255, 255, 255, 0.432); }
    .mapBar1 .ui.vertical.menu .active.item {
      background: rgba(0, 128, 0, 0.4);
      border: 1px solid #02ff02;
      color: #02ff02; }
    .mapBar1 .ui.vertical.menu .active.item:hover {
      color: white; }

.key {
  border-radius: 1rem;
  text-align: right;
  position: absolute;
  bottom: 30px;
  z-index: 999;
  right: 0;
  width: 160px;
  background: rgba(250, 250, 250, 0.4);
  color: white; }
  .key p {
    text-align: right;
    margin: 0.8rem 0;
    font-size: 1rem; }
  .key .icon {
    margin-right: 0.4rem; }

.key.dark {
  background: rgba(87, 87, 87, 0.8);
  color: 222; }

.broadNet {
  color: #4694c2 !important; }

.status-green {
  background: rgba(33, 186, 69, 0.1) !important; }

.status-red {
  background: rgba(251, 8, 8, 0.2) !important; }

.status-yellow {
  background: rgba(232, 142, 43, 0.2) !important; }

@media only screen and (max-width: 600px) {
  .ui.container.wrapper {
    padding: 0;
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .ui.container.pageWrapper {
    padding: 0;
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .mainContent {
    padding: 0 !important; }
    .mainContent .ui.container {
      width: auto !important;
      margin-left: 0.2rem !important;
      margin-right: 0.2rem !important; }
    .mainContent .ui.segment {
      padding: 0.5rem !important; }
  .ui.grid > .stackable.stackable.row > .column,
  .ui.stackable.grid > .column.grid > .column,
  .ui.stackable.grid > .column.row > .column,
  .ui.stackable.grid > .column:not(.row),
  .ui.stackable.grid > .row > .column,
  .ui.stackable.grid > .row > .wide.column,
  .ui.stackable.grid > .wide.column {
    padding: 1rem 0rem !important; }
  .ui.fluid.button,
  .ui.fluid.buttons {
    width: 80%;
    margin: 0.5rem auto 0; }
  .ui.menu {
    font-size: 1rem !important;
    font-family: "Exo", sans-serif;
    margin: 0; }
  .data p {
    font-size: 0.9rem !important; }
  .ui.header {
    font-size: 1rem !important; }
  .datetime-input__datetimeInput___E_LKs .datetime-input__editPopover___1bQE2 {
    min-width: 100%; }
  .ui.form .hasDate {
    margin-bottom: 3rem; }
  .outerMapHolder {
    margin: 0 !important;
    padding: 0 !important; } }

@media only screen and (max-width: 1024px) {
  .ui.header:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
    font-size: 1rem !important; } }

.progress-bar {
  background-color: #1192e7;
  height: 150px;
  width: 10px;
  left: 30px;
  bottom: 40px;
  border-radius: 5px;
  position: absolute;
  z-index: 2;
  margin: 0 auto; }

.progress-bar-outline {
  background-color: rgba(11, 36, 65, 0.8);
  height: 200px;
  width: 10px;
  left: 30px;
  bottom: 40px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  margin: 0 auto; }

.weatherBar {
  background-color: #0b1720;
  height: 200px;
  width: 100%;
  overflow: auto;
  text-align: center; }

.calendarHolder {
  min-height: 60vh;
  height: 100%;
  padding-bottom: 2rem !important; }
  .calendarHolder span.key {
    padding: 1rem;
    margin-top: 0.5rem; }
  .calendarHolder .rbc-calendar {
    margin-bottom: 0.5rem; }

.rbc-toolbar-label {
  font-size: 1.6rem; }

.rbc-off-range-bg {
  background: #4c4b4b; }

.rbc-today {
  background: #5e7c7c; }

.rbc-toolbar button {
  color: #41b6e6; }

.rbc-show-more {
  background: #4c4b4b;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem; }

a.rbc-show-more {
  color: #41b6e6;
  font-size: 1rem; }

.rbc-day-slot .rbc-time-slot {
  border-top: 0.01rem solid #848484; }

body {
  background: #0b213a;
  color: #fff;
  font-family: "Open Sans", sans-serif; }

.no-pad {
  padding: 0 !important; }

.no-vpad {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.no-hpad {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.no-marg {
  margin: 0 !important; }

.ui.negative.message {
  background-color: rgba(239, 38, 38, 0.35);
  color: #ececec; }
  .ui.negative.message .header {
    color: #ffffff; }

.ui.positive.message {
  background-color: rgba(38, 239, 38, 0.3);
  color: #ececec; }

.ui.message.alerts svg {
  margin-right: 1rem; }

.ui.blue.message {
  background-color: #0d314c;
  color: #b8d8f4;
  box-shadow: 0 0 0 1px #2185d0 inset, 0 0 0 0 transparent; }
  .ui.blue.message .header {
    color: #239bf5; }

.ui.red.message {
  background-color: #3d0b07;
  color: #ffc9c9;
  box-shadow: 0 0 0 1px #db2828 inset, 0 0 0 0 transparent; }
  .ui.red.message .header {
    color: #ff2020; }

.ui.orange.message {
  background-color: #5c2e08;
  color: #f0cab0;
  box-shadow: 0 0 0 1px #f2711c inset, 0 0 0 0 transparent; }
  .ui.orange.message .header {
    color: #ff6a08; }

.padding {
  padding: 1rem !important; }

a {
  color: #b4b4b4;
  text-decoration: none; }
  a :hover {
    color: #fff; }

.subtle {
  color: rgba(199, 199, 199, 0.342); }

.clickable {
  cursor: pointer; }
  .clickable > .value:hover {
    color: #41b6e6 !important; }
  .clickable > .label:hover {
    color: #41b6e6 !important; }

p {
  font-size: 1.2rem; }

.ui.divider {
  color: #fff; }

.ui.attached.segment {
  border: 0; }

.ui.segment,
.ui.horizontal.segments {
  background: #0b1720;
  border: 2px solid #1192e7;
  border-radius: 0; }
  .ui.segment .ui.header,
  .ui.horizontal.segments .ui.header {
    letter-spacing: 0.3rem;
    font-family: Lato, Roboto, "Arial Narrow Bold", sans-serif;
    color: #fff; }
  .ui.segment .formGroup,
  .ui.horizontal.segments .formGroup {
    background: #0b1720; }

.ui.items > .item > .content > .header {
  color: #fff; }

.ui.items > .item .meta {
  color: #fff; }

.ui.modal {
  background: rgba(11, 36, 65, 0.8);
  border: 2px solid #1192e7; }
  .ui.modal .content {
    background: #0b1720; }
  .ui.modal .header,
  .ui.modal .actions {
    color: #fff;
    background: rgba(19, 58, 103, 0.8); }
  .ui.modal .header .content {
    background: rgba(11, 36, 65, 0.8); }

.dataList .item {
  padding: 0.5rem !important;
  font-size: 1.8vh; }

.ui.ui.negative.message {
  display: flex;
  vertical-align: middle; }
  .ui.ui.negative.message svg {
    margin-right: 0.4rem; }

.github-picker {
  background: #0b1720 !important;
  justify-content: center;
  border: 0 !important; }

.ui.segment.box {
  padding: 0;
  border-bottom: 2px solid #1192e7 !important;
  border-top: 2px solid #1192e7 !important; }
  .ui.segment.box .ui.segment.headerBlock {
    background: rgba(11, 36, 65, 0.8) !important;
    padding: 0.6rem 1rem !important;
    border-bottom: 1px solid #1192e7;
    margin: 0 !important;
    justify-content: space-between;
    display: flex;
    align-items: center; }
    .ui.segment.box .ui.segment.headerBlock .ui.header {
      margin: 0;
      background: rgba(11, 36, 65, 0.8) !important; }
    .ui.segment.box .ui.segment.headerBlock .ui.basic.inverted.button {
      box-shadow: 0 0 0 2px #073858 inset !important; }
    .ui.segment.box .ui.segment.headerBlock .ui.basic.inverted.button:hover {
      box-shadow: 0 0 0 2px #0e74b7 inset !important; }
  .ui.segment.box .row {
    padding: 0 !important; }
  .ui.segment.box .column {
    padding: 0 !important; }
  .ui.segment.box .stat {
    padding-right: 2rem;
    padding-left: 2rem; }
  .ui.segment.box .weatherBox {
    margin-bottom: 0; }
    .ui.segment.box .weatherBox small {
      color: #b4b4b4; }
    .ui.segment.box .weatherBox .grid .column {
      padding: 0.5rem !important; }
  .ui.segment.box .ui.button {
    border-radius: 0 !important; }
  .ui.segment.box .ui.segment.paddedBox {
    padding: 1rem !important; }
    .ui.segment.box .ui.segment.paddedBox p {
      margin-bottom: 0.4rem !important; }

.ui.grid.orderedList .column {
  padding: 0.4rem !important; }

.ui.card,
.ui.cards > .card {
  box-shadow: 0 1px 3px 0 #1192e7, 0 0 0 1px #1192e7 !important;
  background: transparent;
  border-radius: 0; }
  .ui.card .content,
  .ui.cards > .card .content {
    padding: 0; }
  .ui.card .extra.content .ui.button,
  .ui.cards > .card .extra.content .ui.button {
    border-radius: 0 !important;
    border: 1px solid #1192e7;
    border-bottom: 0;
    box-shadow: none !important;
    color: #a8a7a7 !important; }
  .ui.card .extra.content .ui.button:hover,
  .ui.cards > .card .extra.content .ui.button:hover {
    color: #fff !important; }
  .ui.card .meta,
  .ui.card .description,
  .ui.cards > .card .meta,
  .ui.cards > .card .description {
    color: #fff !important;
    margin-top: 0 !important; }
    .ui.card .meta .ui.grid.celled,
    .ui.card .description .ui.grid.celled,
    .ui.cards > .card .meta .ui.grid.celled,
    .ui.cards > .card .description .ui.grid.celled {
      border: 1px solid #1192e7 !important; }
  .ui.card .column,
  .ui.cards > .card .column {
    color: #fff !important; }
  .ui.card .header,
  .ui.cards > .card .header {
    background: rgba(11, 36, 65, 0.8);
    color: #fff !important;
    padding: 0.6rem 1rem;
    margin-top: 0 !important;
    border-top: 1px solid #1192e7;
    border-bottom: 1px solid #1192e7;
    letter-spacing: 0.3rem;
    font-family: Lato, Roboto, "Arial Narrow Bold", sans-serif; }
  .ui.card .table,
  .ui.cards > .card .table {
    font-family: Lato, Roboto, "Arial Narrow Bold", sans-serif;
    letter-spacing: 0.1rem; }

.ui.celled.grid {
  width: 100%;
  margin: 1em 0;
  box-shadow: 0 0 0 2px #1192e7; }
  .ui.celled.grid > .row {
    box-shadow: 0 -1px 0 0 #1192e7; }
    .ui.celled.grid > .row > .column {
      box-shadow: -2px 0 0 0 #1192e7; }

> .ui.celled.grid.dataGrid .data .warning {
  background: rgba(239, 38, 38, 0.35); }

.ui.celled.grid.dataGrid .data .ui.header {
  padding: 0.5rem;
  margin: 0;
  text-align: center;
  border-bottom: 1px solid #0a5688;
  color: #b4b4b4;
  background: rgba(18, 46, 82, 0.5); }
  .ui.celled.grid.dataGrid .data .ui.header .icon {
    font-size: 1.4rem;
    color: #41b6e6 !important; }

.ui.celled.grid.dataGrid .data p {
  padding: 0.8rem; }

.ui.celled.grid.dataGrid h4.ui.header {
  background: #0b1720 !important; }

.ui.celled.grid.dataGrid .data.warning {
  background: rgba(239, 38, 38, 0.35); }

.rolesList .ui.button {
  margin: 0.5rem 0;
  font-size: large; }

.statBox .statistic {
  margin: 0 2rem;
  padding-right: 1rem; }

p.condition {
  margin-bottom: 0.5rem;
  min-height: 4rem; }

.ui.selection.visible.dropdown > .text:not(.default) {
  color: #fff; }

.ui.dropdown .menu {
  z-index: 999; }

.ui.segment.statHolder {
  margin: 0 !important;
  display: flex;
  align-content: center;
  height: 100%;
  justify-content: space-around;
  align-items: center; }

.ui.segment.decisionHolder {
  margin: 0 !important;
  display: flex;
  align-content: center;
  height: 100%;
  justify-content: space-around;
  flex-direction: column;
  align-items: center; }

.statBox {
  border: 0 solid #1192e7;
  padding: 1rem;
  text-align: center; }
  .statBox h1,
  .statBox h2,
  .statBox h3,
  .statBox h4 {
    margin: 0; }

.ui.segement.mergeJob p {
  margin: 0;
  color: white; }

.rowBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .rowBox p {
    margin-bottom: 0.2rem; }
    .rowBox p span {
      font-weight: bold;
      color: #41b6e6; }

.sms_filter .ui.checkbox {
  padding: 0.4rem; }

.SMS_RecipientBox .ui.label {
  margin: 0.2rem; }

.ui.info.message {
  box-shadow: 0 0 0 1px #1192e7 inset, 0 0 0 0 transparent;
  background-color: #214370;
  color: #ffffff; }
  .ui.info.message .header {
    color: #ffffff; }

.ui.segment.floodAlert .column {
  padding: 0.6rem !important; }
  .ui.segment.floodAlert .column .ui.header {
    background: rgba(11, 36, 65, 0.8); }

.smsInbox .ui.header {
  font-size: 1.2rem; }
