@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .tooltip {
    @apply invisible absolute;
  }

  .has-tooltip:hover .tooltip {
    @apply visible z-50;
  }
}
